import React, { useState, useEffect } from "react";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";

import Home from "./landing/index";
import Blogs from "./blogs";
import Team from "./team";
import Portfolio from "./portfolio";
import Contact from "./contact";
import Testimonials from "./testimonials";
import About from "./about";
import Privacy from "./privacy";
import Terms from "./terms";
import Login from "./login";

import Knowledge from "./landing/knowledge/knowledge";
import NewsDetails from "./blog/NewsDetails";
import { fetchPortfolio } from "./api/portfolio/fetch_portfolio";

import PortfolioDetails from "./portfolio/PortfolioDetails";


import Faqs from "./faq";


import WebDev from "./webdev";

function App() {
  const [portfolios, setPortfolios] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchPortfolio()
      .then((data) => {
        setPortfolios(data.data.portfolios);
      })
      .catch((error) => {
        setError(error.message);
      });
  }, []);

  if (error) {
    console.error("Error fetching portfolio data:", error);
    return null; // Render nothing if an error occurs
  }

  return (
    <>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blog" element={<Blogs />} />
          <Route path="/blog/:slug" element={<NewsDetails />} /> 


          <Route path="/team" element={<Team />} />
          <Route path="/login" element={<Login />} />
          <Route path="/testimonials" element={<Testimonials />} />

          <Route path="/portfolio" element={<Portfolio />} />

          <Route
            path="/portfolio/:slug"
            element={<PortfolioDetails portfolios={portfolios} />}
          />

          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/knowledge" element={<Knowledge />} />

          <Route path="/faqs" element={<Faqs />} />


          {/* Services Pages */}
          <Route path="/webdev" element={<WebDev />} />


        </Routes>
      </div>
    </>
  );
}
export default App;
