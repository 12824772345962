import React from "react";
import zeroone from "../../assest/1.png";
import zerotwo from "../../assest/2.png";
import zerothree from "../../assest/3.png";
function service() {
  return (
    <>
      <div className="world_main exm">
        <h1 className="world_1">
          Let's have a brief look at  all  <br />{" "}
          <span className="world_2">service areas</span><br/> we work in
        </h1>
        <p className="world_p">
          We offer custom development that follows fast, reliable, and technical
          debt- free processes to make sure that companies grow without worrying
          about any glitches.
        </p>
        <div className="service_1">
          <div className="service_2 border-left">
            <h1 className="service_3">
              Front End <br /> Development
              <img src={zeroone} alt="Images" className="zeroone" />
            </h1>
            <p className="service_4">
              We help you turn your concept into a product by assessing business
              requirements, analyzing product features, functional &
              non-functional…
            </p>
 
          </div>
          <div className="service_2">
            <h1 className="service_3">
              Back End <br /> Development
              <img src={zerotwo} alt="Images" className="zeroone" />
            </h1>
            <p className="service_4">
              We offer mobile application development for iOS and Android
              platforms, creating high-quality, user-friendly and feature-rich
              apps.
            </p>

          </div>
          <div className="service_2 border-left">
            <h1 className="service_3">
              CRM & MANAGEMENT SYSTEMS
              <img src={zerothree} alt="Images" className="zeroone" />
            </h1>
            <p className="service_4">
              We provide custom web application development, delivering high-
              performance, custom-built solutions that meet the needs of our
              clients.
            </p>

          </div>
        </div>
      </div>
    </>
  );
}

export default service;
