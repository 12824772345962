import React from "react";
import { Link } from "react-router-dom";
import Historyimg from "../../assest/Image-2.webp.png";
function history() {
  return (
    <>
      <div className="history_main">
        <div className="history_1">
          <div className="history_2 desktop_historyimage">
            <img src={Historyimg} alt="Images" className="historybg" />
          </div>
          <div className="history_3">
            <h1 className="history_4">
              Agency <span className="historyspan">History</span> & background
            </h1>
            <p className="history_5">
              Prestige Perfections Web Agency is where innovation meets
              excellence in the digital realm. Established in 2019, we are a
              dynamic agency driven by a passion for transforming businesses
              through cutting-edge digital solutions.
            </p>
            <div className="history_2 mobile_historyimage">
              <img src={Historyimg} alt="Images" className="historybg" />
            </div>
            <Link
              target="_blank"
              to="https://calendly.com/prestigeperfections/prestige-web-agency"
            >
              <button className="btnhistory">Book a Call Now</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default history;
