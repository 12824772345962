import React from "react";
import T1 from "../../assest/Rectangle 22037.png";
import T2 from "../../assest/Rectangle 22038.png";
import T3 from "../../assest/Rectangle 22039.png";
import W1 from "../../assest/wiseguymo.jpg"
import Jain from "../../assest/jain.jpeg"
import Sean from "../../assest/sean.jpg"
import Akhil from "../../assest/Akhil.jpg"
import Quotes from "../../assest/quote.svg";
// import Star from "../../assest/star.png";
// import { Navigation, Autoplay } from "swiper/modules";
// import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import omar from '../../assest/omar.jpeg';
import {Helmet} from "react-helmet";
function testimonials() {
  const testimonialsData = [
    {
      pic: T3,
      name: "Ajaz Ali",
      position: "Director of Kenneth Jones Solicitors",
      message:
        "Thank you Prestige Perfections for a fantastic website. I have noticed a huge increase in business via our website since coming over to you guys. Thank you once again and keep up the good work.",
    },
    {
      pic: T1,
      name: "Bhavya Munjal",
      position: "CEO of BFit World",
      message:
        "Great Works carried out by Team Prestige Perfection. Really satisfied with the lead generation and complete business branding and PR work.Would highly recommend.",
    },
    {
      pic: T2,
      name: "J.R. Mckee",
      position: "CEO of Label Gold",
      message: "I have worked with Prestige Perfection many times over the past few years assigning them different projects both in the UK and in the USA. They are certainly a cut above the rest and being a subject matter expert in their field, their values are multiplied tenfold.",
    },
    {
      pic: Jain,
      name: "Rishi Jain",
      position: "CEO of Jain & Co. Inc.",
      message: "Prestige perfections is the most professional social media management company. They fulfilled and exceeded my expectations as a client. In the past I had dealt with some shoddy service providers who did not meet the requirements. Prestige perfections delivered their service in timely and professional fashion. I would definitely recommend them to any of my colleagues.",
    },
    {
      pic: omar,
      name: "Omar Arshad",
      position: "CEO OF ASCEND ECOM",
      message: "I had a great experience working with prestige perfections and the owner Saqib Malik, these guys are the best at what they do, and that’s delivering prestige results. We got prestige perfections on board and they helped put together a successful digital marketing strategy which was fast and effective to implement and this actually yielded brilliant results for us in terms of social media, SEO, and content.",
    },
    {
      pic: W1,
      name: "Muhammad Abdul",
      position: "CEO of Verified Marketing Ltd",
      message: "Prestige Perfection have provided us with a comprehensive, fast and well planned digital marketing strategy that has yielded great results in terms of content, SEO, Social Media. The people working are a pleasure to work with, as well as being fast to respond and adapt to the needs of your brand.",
    },
    {
      pic: Sean,
      name: "Sean Borg",
      position: "Journalist",
      message: "Over the years, Prestige Perfections have worked with me on everything from brand design, video, photography, copy, online and print. Projects are always approached with enthusiasm, care and a focus to deliver on-time and within the agreed budget.",
    },

    {
      pic: Akhil,
      name: "Akhilendra Sahu",
      position: "CEO ASTNT Technologies Pvt Ltd",
      message: "The support we gained from our sessions with Prestige Perfections have allowed us to understand that working on the business is just as important as working in the business. In our sessions we worked with Saqib Malik to understand why our current customers have purchased from us and who are the customers we want to work with in the future. With Saqib we set out our core message as a business and now better understand our core values and why customers should work with us.",
    },


    // Repeat the above object structure for the desired number of times (5 in this case)
  ];
  return (
    <>
      <Helmet>
        <title>Testimonials | Prestige Perfections</title>
        <meta
          name="description"
          content="We are very happy about our completed projects"
        />
      </Helmet>
      
      <div className="testi_main">
        <h1 className="world_1">
          CLIENT<span className="world_2"> TESTIMONIALS</span>
        </h1>
        <p className="world_p">
          Explore what our clients love about us in their own words
        </p>

        <div className="testi_1">
          {testimonialsData.map((testimonial, index) => (
            <div key={index} className="t_cards">
              <img src={Quotes} alt="Quote" className="tabler-quote" />
              <div className="t_cards_1">
                <img
                  src={testimonial.pic}
                  alt="Images"
                  className="t-cards_img"
                />
                <h1 className="testi_3">{testimonial.message}</h1>
                <p className="testi_4">{testimonial.name}</p>
                <p className="testi_5">{testimonial.position}</p>
                {/* <img src={Star} alt="Star" className="star_card" /> */}
              </div>
            </div>
          ))}

          {/* <Swiper
            className="myswiper testswip"
            modules={[Navigation, Autoplay]}
            spaceBetween={10}
            navigation
            loop={true}
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)}
            breakpoints={{
              640: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 2,
              },
              1200: {
                slidesPerView: 3,
              },
            }}
          >
            <SwiperSlide>
              <div className="testi_2">
                <img src={T3} alt="Images" className="userimg" />
                <div>
                  <h1 className="testi_3">
                  Thank you Prestige Perfections for a fantastic website. I have noticed a huge increase in business via our website since coming over to you guys. Thank you once again and keep up the good work.
                  </h1>
                  <p className="testi_4">Ajaz Ali</p>
                  <p className="testi_5">Director of Kenneth Jones Solicitors</p>
                  <img src={Star} alt="Star" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="testi_2">
                <img src={T1} className="t1img userimg" alt="Images" />
                <div>
                  <h1 className="testi_3">
                    Great Works carried out by Team Prestige Perfection. Really
                    satisfied with the lead generation and complete business
                    branding and PR work.Would highly recommend.
                  </h1>
                  <p className="testi_4">Bhavya Munjal</p>
                  <p className="testi_5">CEO of BFit World</p>
                  <img src={Star} alt="Star" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="testi_2">
                <img className="userimg" src={T2} alt="Images" />
                <div>
                  <h1 className="testi_3">
                  I have worked with Prestige Perfection many times over the past few years assigning them different projects both in the UK and in the USA. They are certainly a cut above the rest and being a subject matter expert in their field, their values are multiplied tenfold
                  </h1>
                  <p className="testi_4"> J.R. Mckee</p>
                  <p className="testi_5">CEO of Label Gold</p>
                  <img src={Star} alt="Star" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="testi_2">
                <img src={T1} className="t1img userimg" alt="Images" />
                <div>
                  <h1 className="testi_3">
                    Great Works carried out by Team Prestige Perfection. Really
                    satisfied with the lead generation and complete business
                    branding and PR work.Would highly recommend.
                  </h1>
                  <p className="testi_4">Bhavya Munjal</p>
                  <p className="testi_5">CEO of BFit World</p>
                  <img src={Star} alt="Star" />
                </div>
              </div>
            </SwiperSlide>
          </Swiper> */}
        </div>
      </div>
    </>
  );
}

export default testimonials;
