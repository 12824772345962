import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchPortfolio } from "../../api/portfolio/fetch_portfolio";

function Projects() {
  const [portfolios, setPortfolios] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchPortfolio()
      .then((data) => {
        setPortfolios(data.data.portfolios);
      })
      .catch((error) => {
        console.error("Error fetching portfolio data:", error);
      });
  }, []);

  const handleClick = (portfolio) => {
    navigate(`/portfolio/${portfolio.slug}`); // Use slug instead of name
  };

  return (
    <div className="projects_main">
      <h1 className="world_1">
        Our <span className="world_2"> portfolio</span>
      </h1>
      <p className="world_p">We are very happy about our completed projects</p>

      <div className="projects_1 k1-update">
        {portfolios.map((item, index) => (
          <div className="port" key={index} onClick={() => handleClick(item)}>
            <div className="opacity"></div>
            <h1>{item.name}</h1>
            <img className="kimg" src={item.image} alt="Images" />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Projects;
