import React from "react";
import Nav from "./landing/nav/nav";
import Footer from "./landing/footer/footer";
import {Helmet} from "react-helmet";


function terms() {
    return (
        <>
            <Helmet>
                <title>Terms and Conditions | Prestige Perfections</title>
            </Helmet>
            <Nav />

            <div className="team_main">
                <h1 className="world_1">Terms  <span className="world_2"> and</span> Conditions</h1>
                <p className='world_p'>Please read the terms and conditions before making any important action on our site</p>

<p>


<h3>Acceptance of Terms</h3>

Welcome to Prestige Perfections Web Agency. By accessing and using our website (prestigeperfections.agency) and our services, you agree to comply with and be bound by these Terms and Conditions. If you do not agree with any part of these terms, please do not use our website or services.

<h3>Services</h3>

Prestige Perfections Web Agency offers a range of web development and design services. The details of these services, including pricing and terms, are provided on our website and may be subject to change. We reserve the right to modify or discontinue any aspect of our services without notice.

<h3>Client Responsibilities</h3>

Clients engaging our services are responsible for providing accurate and complete information necessary for the project. You agree to collaborate with us, respond promptly to inquiries, and provide any materials required for the project in a timely manner.

<h3>Payment Terms</h3>
Payment details, including fees and invoicing procedures, are outlined in our pricing and payment terms. Clients agree to make payments according to the agreed-upon terms. Failure to pay may result in the suspension of services.

<h3>Intellectual Property</h3>
Unless otherwise agreed upon, Prestige Perfections Web Agency retains the intellectual property rights to the designs, code, and other materials produced during the course of our services. Clients are granted a license to use the deliverables for their intended purpose upon full payment.

<h3>Confidentiality</h3>
We respect the confidentiality of our clients' information. Both parties agree not to disclose confidential information to third parties without consent. We may use client projects as part of our portfolio unless otherwise specified in writing.

<h3>Limitation of Liability</h3>
Prestige Perfections Web Agency is not liable for any damages, including but not limited to direct, indirect, incidental, or consequential damages arising out of the use of our website or services. We make no warranties, express or implied, regarding the reliability or suitability of our services.

<h3>Termination</h3>
Either party may terminate a project or service with written notice. In the event of termination, clients are responsible for any fees for services rendered up to the termination date.

<h3>Governing Law</h3>
These Terms and Conditions are governed by and construed in accordance with the laws of [Your Jurisdiction]. Any disputes arising under or in connection with these terms shall be subject to the exclusive jurisdiction of the courts in [Your Jurisdiction].

<h3>Changes to Terms and Conditions</h3>
We reserve the right to update or modify these Terms and Conditions at any time. The latest version will be posted on our website, and the date of the last update will be indicated.

<h3>Contact Us</h3>
If you have any questions or concerns about these Terms and Conditions, please contact us at info[at]prestigeperfections.com.
</p>








            </div>

            <Footer />
        </>
    );
}

export default terms;
