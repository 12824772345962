import React, { useState } from "react";
import "./loginform.css";
// import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";


function Login() {
  const navigate = useNavigate();
  const [showLoginForm, setShowLoginForm] = useState(true);
  const [showPasswordResetForm, setShowPasswordResetForm] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const toggleForm = (current) => {
    if (showLoginForm ^ (current === "Login")) {
      setShowLoginForm(!showLoginForm);
      setShowPasswordResetForm(false);
    }
  };



  const handleLogIn = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      alert("Please fill in both email and password");
      return;
    }

    try {
      const response = await fetch(
        "https://prestigeperfections.agency/backend/admin-auth/login.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
            password: password,
          }),
        }
      );

      const data = await response.json();
      if (data.status === 200) {
        localStorage.setItem("token", data.token);
        toast.success(data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setTimeout(() => {
          navigate("/");
        }, 2000);
      } else {
        toast.error(data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
      console.log(data);
    } catch (error) {
      console.error("Error logging in:", error);
    }
  };
  return (
    <>
      <div className="boost">
        <div className="boost_1">
          {/* <img src={up} alt="Logo" className="up" />
          <img src={bottom} alt="Logo" className="bottom" />
          <img src={mt} alt="Logo" className="up mt" />

          <img src={mb} alt="Logo" className="bottom mt" />
          <img src={logo} alt="Logo" className="logo" /> */}
        </div>
        <div className="boost_3">
          <div className="boost_4">
            <div className="boost_5" onClick={() => toggleForm("Login")}>
              <h1 className={`login ${showLoginForm ? "border-b" : ""}`}>
                Login
              </h1>
            </div>
          </div>
          <div className="boost_10">
            {showLoginForm ? (
              <div>
                <h1 className="boost_8">Hello Again!</h1>
                <p className="boost_9">Welcome Back</p>
                <form className="boost_11">
                  <p className="boost_12">Enter your Email Address</p>

                  <div className="boost_13">
                    <svg
                      className="message"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1.5 5.25L2.25 4.5H21.75L22.5 5.25V18.75L21.75 19.5H2.25L1.5 18.75V5.25ZM3 6.8025V18H21V6.804L12.465 13.35H11.55L3 6.8025ZM19.545 6H4.455L12 11.8035L19.545 6Z"
                        fill="#333333"
                        opacity="0.3"
                      />
                    </svg>
                    <input
                      type="text"
                      placeholder="Email Address"
                      className="boost_14"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <br />
                  <p className="boost_12">Enter your Password</p>
                  <div className="boost_13">
                    <svg
                      className="message"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M20 12C20 10.897 19.103 10 18 10H17V7C17 4.243 14.757 2 12 2C9.243 2 7 4.243 7 7V10H6C4.897 10 4 10.897 4 12V20C4 21.103 4.897 22 6 22H18C19.103 22 20 21.103 20 20V12ZM9 7C9 5.346 10.346 4 12 4C13.654 4 15 5.346 15 7V10H9V7Z"
                        fill="#333333"
                        opacity="0.3"
                      />
                    </svg>
                    <input
                      type="password"
                      placeholder="Password"
                      className="boost_14"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className="r_me">
                    <input type="checkbox" />
                    <p>Remember me</p>
                  </div>
                  <button onClick={handleLogIn} className="loginbtn">
                    Login
                  </button>
                </form>
              </div>
            ) : showPasswordResetForm ? (
              <div>
                <h1 className="boost_8">Reset Password</h1>
                <p className="boost_9">Welcome Back</p>
                <form className="boost_11">
                  <p className="boost_12">
                    Enter your Email Address to reset your password
                  </p>
                  <div className="boost_13">
                    <svg
                      className="message"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1.5 5.25L2.25 4.5H21.75L22.5 5.25V18.75L21.75 19.5H2.25L1.5 18.75V5.25ZM3 6.8025V18H21V6.804L12.465 13.35H11.55L3 6.8025ZM19.545 6H4.455L12 11.8035L19.545 6Z"
                        fill="#333333"
                        opacity="0.3"
                      />
                    </svg>
                    <input
                      type="text"
                      placeholder="Email Address"
                      className="boost_14"
                    />
                  </div>
                  <button className="loginbtn">Reset Password</button>
                </form>
              </div>
            ) : (
              <div id="#signup">
                <h1 className="boost_8 s-up">Sign Up!</h1>
                <p className="boost_9">Welcome Back</p>
                <form className="boost_11 s-up-form">
                  <div className="boost_13">
                    <svg
                      className="message"
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="#333333"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path
                        d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0"
                        opacity="0.3"
                      />
                      <path
                        d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"
                        opacity="0.3"
                      />
                    </svg>

                    <input
                      type="text"
                      placeholder="First Name"
                      className="boost_14"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                  <br />
                  <div className="boost_13">
                    <svg
                      className="message"
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="#333333"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path
                        d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0"
                        opacity="0.3"
                      />
                      <path
                        d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"
                        opacity="0.3"
                      />
                    </svg>
                    <input
                      type="text"
                      placeholder="Last Name"
                      className="boost_14"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                  <br />
                  <div className="boost_13">
                    <svg
                      className="message"
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="#333333"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path
                        d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0"
                        opacity="0.3"
                      />
                      <path
                        d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"
                        opacity="0.3"
                      />
                    </svg>
                    <input
                      type="text"
                      placeholder="Username"
                      className="boost_14"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </div>
                  <br />
                  <div className="boost_13">
                    <svg
                      className="message"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1.5 5.25L2.25 4.5H21.75L22.5 5.25V18.75L21.75 19.5H2.25L1.5 18.75V5.25ZM3 6.8025V18H21V6.804L12.465 13.35H11.55L3 6.8025ZM19.545 6H4.455L12 11.8035L19.545 6Z"
                        fill="#333333"
                        opacity="0.3"
                      />
                    </svg>
                    <input
                      type="text"
                      placeholder="Email Address"
                      className="boost_14"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <br />
                  <div className="boost_13">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="message"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="#333333"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path
                        d="M6 5a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2v-14z"
                        opacity="0.3"
                      />
                      <path d="M11 4h2" opacity="0.3" />
                      <path d="M12 17v.01" opacity="0.3" />
                    </svg>

                    <input
                      type="text"
                      placeholder="Phone Number"
                      className="boost_14"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </div>
                  <br />
                  <div className="boost_13">
                    <svg
                      className="message"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M20 12C20 10.897 19.103 10 18 10H17V7C17 4.243 14.757 2 12 2C9.243 2 7 4.243 7 7V10H6C4.897 10 4 10.897 4 12V20C4 21.103 4.897 22 6 22H18C19.103 22 20 21.103 20 20V12ZM9 7C9 5.346 10.346 4 12 4C13.654 4 15 5.346 15 7V10H9V7Z"
                        fill="#333333"
                        opacity="0.3"
                      />
                    </svg>
                    <input
                      type="password"
                      placeholder="Password"
                      className="boost_14"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <br />
                  <div className="boost_13">
                    <svg
                      className="message"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M20 12C20 10.897 19.103 10 18 10H17V7C17 4.243 14.757 2 12 2C9.243 2 7 4.243 7 7V10H6C4.897 10 4 10.897 4 12V20C4 21.103 4.897 22 6 22H18C19.103 22 20 21.103 20 20V12ZM9 7C9 5.346 10.346 4 12 4C13.654 4 15 5.346 15 7V10H9V7Z"
                        fill="#333333"
                        opacity="0.3"
                      />
                    </svg>
                    <input
                      type="password"
                      placeholder="Confirm Password"
                      className="boost_14"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </div>
                  <button className="loginbtn">
                    Sign Up!
                  </button>
                </form>
              </div>
            )}
            <hr className="hr s-up-hr" />
          </div>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover
            theme="dark"
          />
        </div>
      </div>
    </>
  );
}
export default Login;
