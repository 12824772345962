import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
// import ReactHtmlParser from "react-html-parser";

import { Helmet } from "react-helmet";
import Nav from "../landing/nav/nav";
import Footer from "../landing/footer/footer";

import Linked from "../../src/assest/linked.png";
import Fb from "../../src/assest/fb.png";
import X from "../../src/assest/x.png";
import Insta from "../../src/assest/insta.png";
import Tik from "../../src/assest/tik.png";
import Web from "../../src/assest/web.png";
// import Dot from "../../src/assest/dot.png";
import Map from "../../src/assest/map.png";
import Bag from "../../src/assest/bag.png";
import Box from "../../src/assest/box.png";

import F11 from "../../src/assest/tick.png";

// import Star from "../../src/assest/star.png";
import Play from "../../src/assest/play.png";

const PortfolioContent = ({ htmlContent }) => (
  <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
);

const PortfolioDetails = ({ portfolios }) => {
  //
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleOutsideClick = (event) => {
    if (event.target.className === "modal1") {
      closeModal();
    }
  };
  //

  const { slug } = useParams();
  const [portfolio, setPortfolio] = useState(null);

  useEffect(() => {
    const foundPortfolio = portfolios.find((p) => p.slug === slug);
    if (foundPortfolio) {
      setPortfolio(foundPortfolio);
    }
  }, [portfolios, slug]);

  if (!portfolio) {
    return (
      <div>
        <Nav />
        <div className="loading"></div>
        <Footer />
      </div>
    );
  }

  const { testimonial } = portfolio;

  // if (!portfolio) {
  //   return <div>Portfolio not found</div>;
  // }

  const solutionItems = [
    { name: "Public Relations", image: F11, key: "solution_pr" },
    { name: "Web Development", image: F11, key: "solution_webdev" },
    { name: "Knowledge Panel", image: F11, key: "solution_gkp" },
    { name: "Ads", image: F11, key: "solution_ads" },
  ];

  return (
    <>


      <Helmet>
        <title>{portfolio.name} | Prestige Perfections</title>
        <meta name="description" content='This is the amazing work we have done for our valuable client' />
      </Helmet>


      <Nav />
      <div className="case__a">
        <div className="case__b">
          {/* <img src={Dot} alt="Dot" className="dots" /> */}
          <div className="case__c">
            <img src={portfolio.image} alt="Guy" />
          </div>
          <div className="case__d">
            <div className="case__d_1"></div>
            <h2>{portfolio.name}</h2>
            <p>{portfolio.overview}</p>
            <div className="case__d_2">
              {portfolio.site && (
                <Link to={portfolio.site}>
                  <img src={Web} alt="Web" />
                </Link>
              )}
              {portfolio.social_in && (
                <Link to={portfolio.social_in}>
                  <img src={Linked} alt="Linked" />
                </Link>
              )}
              {portfolio.social_fb && (
                <Link to={portfolio.social_fb}>
                  <img src={Fb} alt="Fb" />
                </Link>
              )}
              {portfolio.social_insta && (
                <Link to={portfolio.social_insta}>
                  <img src={Insta} alt="Insta" />
                </Link>
              )}
              {portfolio.social_x && (
                <Link to={portfolio.social_x}>
                  <img src={X} alt="X" />
                </Link>
              )}
              {portfolio.social_tiktok && (
                <Link to={portfolio.social_tiktok}>
                  <img src={Tik} alt="Tik" />
                </Link>
              )}
            </div>
          </div>
        </div>

        <div className="case__e">
          {portfolio.location && (
            <div className="case__e_1">
              <img src={Map} alt="Map" />
              {portfolio.location}
            </div>
          )}
          {portfolio.industry && (
            <div className="case__e_1">
              <img src={Bag} alt="Bag" />
              {portfolio.industry}
            </div>
          )}
          {portfolio.sector && (
            <div className="case__e_1">
              <img src={Box} alt="Box" />
              {portfolio.sector}
            </div>
          )}
        </div>
      </div>
      <div className="faced">
        <h2>
          FACED <span>CHALLENGES</span>
        </h2>
        <p>
          <PortfolioContent htmlContent={portfolio.objective} />
        </p>
      </div>
      <div className="faced__a">
        <div className="faced">
          <h2>
            <span>Solutions</span> By Prestige Perfections
          </h2>
        </div>

        <div className="faced__b">
          <div className="faced__b_1 faced__b_ex">
            <p>
              <PortfolioContent htmlContent={portfolio.strategy} />
            </p>
            <br />
            <div className="faced">
              <h2>
                <span>Results</span>
              </h2>
            </div>
            <br />
            <div className="faced__b_1">
              <div className="faced__flex">
                <p>
                  <PortfolioContent htmlContent={portfolio.result} />
                </p>
              </div>
            </div>
          </div>

          {/* <svg
            className="faced_line"
            width={2}
            height="100"
            viewBox="0 0 2 1199"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="1.08972"
              y1="0.95105"
              x2="1.08972"
              y2="1198.95"
              stroke="#DCDCDC"
            />
          </svg> */}

          <div className="faced__b_2">
            {portfolio.media_placements.length > 0 && (
              <>
                <h2>Media Placements</h2>
                {portfolio.media_placements.map((placement, index) => (
                  <a
                    href={placement.url}
                    key={index}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <img
                      className="media_logos"
                      src={placement.image}
                      alt={placement.outlet}
                      style={{ maxWidth: "200px", maxHeight: "50px" }}
                    />
                  </a>
                ))}

                <svg
                  className="faced_line my10"
                  width={254}
                  height={1}
                  viewBox="0 0 254 1"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    y1="0.7"
                    x2={254}
                    y2="0.7"
                    stroke="#DCDCDC"
                    strokeWidth="0.6"
                  />
                </svg>
              </>
            )}
            {solutionItems.filter((item) => portfolio[item.key] === "1")
              .length > 0 && <h2>Solutions</h2>}
            <div>
              {solutionItems
                .filter((item) => portfolio[item.key] === "1") // Filter out items with value 1
                .map((item, index) => (
                  <div className="public" key={index}>
                    <img src={item.image} alt={item.name} />
                    {item.name}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>

      {portfolio.testimonial_id && testimonial && (
        <div className="case__a pb20">
          <div className="faced">
            <h2>
              Client <span>TESTIMONIAL</span>
            </h2>
          </div>

          <div className="case__b pxextra">
            <div className="case__c">
              <div className="guybg"></div>
              <img src={testimonial.image} alt="Guy" />
            </div>
            <div className="case__d pxextra1">
              <p>{testimonial.msg}</p>

              {/* <div className="case__d_2">
                {testimonial.stars &&
                  !isNaN(parseInt(testimonial.stars)) &&
                  [...Array(parseInt(testimonial.stars))].map((_, i) => (
                    <img key={i} src={Star} alt="Star" />
                  ))}
              </div> */}

              <svg
                width={317}
                height={1}
                viewBox="0 0 317 1"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="0.122681" width="316.122" height={1} fill="#484848" />
              </svg>
              <div className="star__a">
                <div className="star__b">
                  <h3>{testimonial.name}</h3>
                  <h5>{testimonial.title}</h5>
                </div>

                {testimonial.has_vid === '1' && (
  <div className="star__c">
    <a
      href={testimonial.vid_url}
      target="_blank"
      rel="noopener noreferrer"
    >
      <span onClick={openModal} className="vidplay">
        See Video
      </span>
      <img src={Play} alt="Play" />
    </a>
  </div>
)}


              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal */}
      {isModalOpen && (
        <div className="modal1" onClick={handleOutsideClick}>
          <div className="modal2">
            <span onClick={closeModal}>X</span>
            <iframe
              style={{ width: "100%", height: "450px" }}
              // src="https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4"
              src={testimonial.vid_url}
              title="UCURS: Your Easy, Affordable Solution for Creating Websites"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            />
          </div>
        </div>
      )}

      {/* Modal */}

      <Footer />
    </>
  );
};

export default PortfolioDetails;
