import React, { useState } from "react";
import C1 from "../../assest/contact.png";
// import C2 from "../../assest/call.png";
// import C3 from "../../assest/world1.png";
// import C4 from "../../assest/mess.png";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "Web Development",
    contact: "",
    message: "",
    securityAnswer: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
      valid = false;
    }

    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
      valid = false;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email.trim())) {
      newErrors.email = "Invalid email format";
      valid = false;
    }

    if (!formData.contact.trim()) {
      newErrors.contact = "Contact is required";
      valid = false;
    }

    if (!formData.message.trim()) {
      newErrors.message = "Message is required";
      valid = false;
    }

    if (!formData.securityAnswer.trim() || formData.securityAnswer.trim() !== "4") {
      newErrors.securityAnswer = "Incorrect answer";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        const response = await fetch("https://prestigeperfections.agency/contact.php", {
          method: "POST",
          body: new FormData(e.target),
        });
        const result = await response.json();

        alert(result.message);

        if (result.status === "success") {
          // Additional success logic if needed

        // Clear the form data
        setFormData({
          name: "",
          email: "",
          subject: "Web Development",
          contact: "",
          message: "",
          securityAnswer: "",
        });
        
        // Optionally, refresh the page
        window.location.reload();


        } else {
          // Additional error logic if needed
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  return (
    <>
      <div className="contact_main">
        <img src={C1} className="contactbg" alt="Contact" />
        <div className="contact_main1">
          <div className="contact_1">
            <h1 className="world_1 ch">
              WE
              <span className="world_2 ch"> LOVE MEETING</span> NEW PEOPLE AND
              HELPING THEM.
            </h1>

            <form className="form1" action="https://prestigeperfections.agency/contact.php" method="post" onSubmit={handleSubmit}>
              <div className="form2">
                <p>Name</p>
                <input className="formname" placeholder="Your Full Name" name="name" onChange={handleChange} />
                {errors.name && <p className="error">{errors.name}</p>}
              </div>
              <div className="form2">
                <p>Email</p>
                <input className="formname" placeholder="Enter your email address" name="email" onChange={handleChange} />
                {errors.email && <p className="error">{errors.email}</p>}
              </div>
              <div className="form2 subject">
                <svg
                  className="subject-arrow"
                  width={19}
                  height={15}
                  viewBox="0 0 19 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M9.49999 15L0.406728 3.17431e-07L18.5933 1.90735e-06L9.49999 15Z" fill="#EE1B50" />
                </svg>
                <p>Subject</p>
                <select className="formname" name="subject" onChange={handleChange}>
                  <option>Web Development</option>
                  <option>Consultation</option>
                  <option>Other</option>
                </select>
              </div>
              <div className="form2">
                <p>Contact</p>
                <input className="formname" placeholder="Phone Number" name="contact" onChange={handleChange} />
                {errors.contact && <p className="error">{errors.contact}</p>}
              </div>
              <div className="form3">
                <p>Message</p>
                <textarea className="formmessage" placeholder="Enter your message" name="message" onChange={handleChange}></textarea>
                {errors.message && <p className="error">{errors.message}</p>}
              </div>
              <div className="form3">
                <p>Security Question</p>
                <div className="security">
                  <p>2+2 =</p>
                  <input className="formname" type="text" name="securityAnswer" onChange={handleChange} />
                  {errors.securityAnswer && <p className="error">{errors.securityAnswer}</p>}
                </div>
              </div>
              <button className="btnsend" type="submit">
                SEND NOW
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
