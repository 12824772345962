import React from "react";
import profitimg from "../../assest/profit.png";
function profit() {
  return (
    <>
      <div className="profit_main">
        <div className="profit_1">
          <h1>
            We develop the best path to <span className="profitspan">get successful</span> business <span className="profitspan">which can </span> make you profitable.
          </h1>
          <p>
            Your success is directly linked to ours. So we keep this thing as
            our top priority that we serve with all possible ways to make your
            product fully successful.
          </p>
        </div>
        <div className="profit_2">
          <img src={profitimg} alt="Images" className="profitimg" />
        </div>
      </div>
    </>
  );
}

export default profit;
