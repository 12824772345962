import React, { useState } from "react";

function Question({ question, answer, height }) {
  const [showQ, setShowQ] = useState(false);

  return (
    <>
      <div className="w-full faq">
        <div
          className={`flex items-center md:px-8 px-4 justify-between w-full cursor-pointer gap-4 py-1 ${
            showQ ? "bg-[#EE1B50] rounded-t-lg " : "bg-white rounded-lg "
          }`}
          onClick={() => setShowQ(!showQ)}
        >
          <p
            className={`md:text-[20px] text-xs font-normal uppercase ${
              showQ ? "text-white" : "text-[#404040]"
            }`}
          >
            {question}
          </p>
          <div className="flex flex-shrink-0 justify-end items-center w-16 md:h-16 h-10">
            <svg
              className={`flex-shrink-0 flex w-6 h-6 border-2 rounded-full p-1  ${
                showQ
                  ? "text-white border-white"
                  : "text-[#EE1B50] border-[#EE1B50]"
              }`}
              width={35}
              height={32}
              viewBox="0 0 35 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                className={`duration-300 ease-in-out ${
                  showQ ? ` opacity-0 ` : `opacity-100 `
                }`}
                x1={18}
                y1="5.78908e-10"
                x2={18}
                y2={32}
                stroke="currentColor"
                strokeWidth={4}
              />
              <line
                x1={35}
                y1={17}
                y2={17}
                stroke="currentColor"
                strokeWidth={4}
              />
            </svg>
          </div>
        </div>
        <div
          className={`overflow-hidden flex items-center justify-start duration-300 ease-in-out px-5 ${
            showQ
              ? ` mt-4 h-[${height}px] duration-400 lg:h-[8rem] pb-4 `
              : `h-0 `
          }`}
        >
          <p
            className={`md:text-lg text-xs leading-6 text-[#737071] py-2 px-1 xl:pr-60 pl-2`}
          >
            {answer}
          </p>
        </div>
      </div>
    </>
  );
}

export default Question;
