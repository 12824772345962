import React from "react";
import Nav from "./landing/nav/nav";
import Footer from "./landing/footer/footer";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import Herobg from "./assest/hero.png"
import Worldimg from "./assest/world.png";
import choosebg from "./assest/reason.png"

import zeroone from "./assest/1.png";
import zerotwo from "./assest/2.png";
import zerothree from "./assest/3.png";

import Historyimg from "./assest/Image-2.webp.png";
import profitimg from "./assest/profit.png";


const Hero = () => {
  return (
    <>
      <div className="hero_main">
        <img src={Herobg} alt="Hero" className="herobg" />
        <div className="hero_main1">
          <h1 className="hero_1">
            Prestige Perfections - {" "}
            <span className="hero_2">
            Elevate Your <br /> {" "} Brand With A
            </span>{" "}
            <span className="hero_3">Stunning Website</span>
          </h1>
          <p className="hero_4">
          You are just one click away to get the best team onboard
          </p>
          <Link
            target="_blank"
            to="https://calendly.com/prestigeperfections/prestige-web-agency"
          >
            <button className="btn2">Book a Call Now</button>
          </Link>
        </div>
      </div>
    </>
  );
}

const World = () => {
  return (
    <>
      <div className="world_main">
        <h1 className="world_1">
          We have developed <span className="world_2"> more than 130</span>{" "}
          Products Websites worldwide
        </h1>
        <p className="world_p">
          All these products are massive success that boost our portfolio in
          Software Development
        </p>
        <div className="world_3">
          <div className="world_4">
            <h1 className="world_4_1">
              We are the best team in the field of Software Development
            </h1>
            <p className="world_4_2">
              We at Prestige Perfections do all our best to become your technology partner so
              we can grow together in all possible dimensions
            </p>
          </div>
          <div className="world_5">
            <img src={Worldimg} alt="Images" />
          </div>
          <div className="world_6">
            <h1 className="world_6_1">
              Mission, vission and{" "}
              <span className="world_6_2">short history</span> of the company
            </h1>
            <p className="world_6_3">
              As a software development company, we utilize technology-driven
              solutions to organize data, get the best planning and bring the
              perfect decision making
              <br />
              <br />
              Our focus is at mutual growth in collaborative way thus making a
              perfect business model
            </p>
            <Link
              target="_blank"
              to="https://calendly.com/prestigeperfections/prestige-web-agency"
            >
              <button className="btn4">
                Book a Call Now
                <svg
                  width={15}
                  height={8}
                  viewBox="0 0 15 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.6294 2.50635V1.08008C10.6294 0.428958 11.4355 0.0878931 11.9006 0.552981L14.5671 3.25049C14.8772 3.52954 14.8772 3.99463 14.5671 4.27368L11.9006 6.97119C11.4355 7.43628 10.6294 7.09522 10.6294 6.44409V4.98682H1.29663C1.07959 4.98682 0.924561 4.83179 0.924561 4.61475V2.87842C0.924561 2.69239 1.07959 2.50635 1.29663 2.50635H10.6294Z"
                    fill="white"
                  />
                </svg>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

const Choose = () =>{
  return (
    <>
    <div className='choose_main'>
        <img src={choosebg} alt='Images' className='choosebg' />
        <h1 className='choose_1'>
        We have <span className='choose_2'>reasons for you</span> to choose us
        </h1>
        <p className='choose_3'>You just need to share your idea and you will see how we convert that into reality</p>
        <p className='choose_4'>We specialize in analyzing, designing, building, deploying and scaling digital tech products with great service quality and speed and more importantly we do it all with passion.</p>
        <div className='choose_5'>
            <div className='choose_6'>
                <h1 className='choose_7'>Top <br/> Professionals</h1>
                <p className='choose_8'>Our vetted agile product development team build top-notch products.</p>
            </div>
            <div className='choose_6'>
                <h1 className='choose_7'>Cost Effective</h1>
                <p className='choose_8'>Our vetted agile product development team build top-notch products.</p>
            </div>
            <div className='choose_6'>
                <h1 className='choose_7'>24/7 customer support</h1>
                <p className='choose_8'>Our vetted agile product development team build top-notch products.</p>
            </div>
            <div className='choose_6'>
                <h1 className='choose_7'>Complete Ownership</h1>
                <p className='choose_8'>Our vetted agile product development team build top-notch products.</p>
            </div>
        </div>
    </div>
    </>
  )
}

const Service = () => {
  return (
    <>
      <div className="world_main exm">
        <h1 className="world_1">
          Let's have a brief look at  all  <br />{" "}
          <span className="world_2">service areas</span><br/> we work in
        </h1>
        <p className="world_p">
          We offer custom development that follows fast, reliable, and technical
          debt- free processes to make sure that companies grow without worrying
          about any glitches.
        </p>
        <div className="service_1">
          <div className="service_2 border-left">
            <h1 className="service_3">
              Front End <br /> Development
              <img src={zeroone} alt="Images" className="zeroone" />
            </h1>
            <p className="service_4">
              We help you turn your concept into a product by assessing business
              requirements, analyzing product features, functional &
              non-functional…
            </p>
 
          </div>
          <div className="service_2">
            <h1 className="service_3">
              Back End <br /> Development
              <img src={zerotwo} alt="Images" className="zeroone" />
            </h1>
            <p className="service_4">
              We offer mobile application development for iOS and Android
              platforms, creating high-quality, user-friendly and feature-rich
              apps.
            </p>

          </div>
          <div className="service_2 border-left">
            <h1 className="service_3">
              CRM & MANAGEMENT SYSTEMS
              <img src={zerothree} alt="Images" className="zeroone" />
            </h1>
            <p className="service_4">
              We provide custom web application development, delivering high-
              performance, custom-built solutions that meet the needs of our
              clients.
            </p>

          </div>
        </div>
      </div>
    </>
  );
}

const History = () => {
  return (
    <>
      <div className="history_main">
        <div className="history_1">
          <div className="history_2 desktop_historyimage">
            <img src={Historyimg} alt="Images" className="historybg" />
          </div>
          <div className="history_3">
            <h1 className="history_4">
              Agency <span className="historyspan">History</span> & background
            </h1>
            <p className="history_5">
              Prestige Perfections Web Agency is where innovation meets
              excellence in the digital realm. Established in 2019, we are a
              dynamic agency driven by a passion for transforming businesses
              through cutting-edge digital solutions.
            </p>
            <div className="history_2 mobile_historyimage">
              <img src={Historyimg} alt="Images" className="historybg" />
            </div>
            <Link
              target="_blank"
              to="https://calendly.com/prestigeperfections/prestige-web-agency"
            >
              <button className="btnhistory">Book a Call Now</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

const Profit = () => {
  return (
    <>
      <div className="profit_main">
        <div className="profit_1">
          <h1>
            We develop the best path to <span className="profitspan">get successful</span> business <span className="profitspan">which can </span> make you profitable.
          </h1>
          <p>
            Your success is directly linked to ours. So we keep this thing as
            our top priority that we serve with all possible ways to make your
            product fully successful.
          </p>
        </div>
        <div className="profit_2">
          <img src={profitimg} alt="Images" className="profitimg" />
        </div>
      </div>
    </>
  );
}


function webdev() {
  return (
    <>

      <Helmet>
        <title>Web & App Development | Prestige Perfections</title>
        <meta name="description" content="We are committed to provide absolute finesse! Welcome to Prestige Perfections Web Agency, the pinnacle of digital ingenuity where every pixel, code, and concept converges to redefine your online presence." />
      </Helmet>


      <Nav />
      <Hero/>
      <World/>
      <Choose/>
      <Service/>
      <History/>
      <Profit/>
      <Footer />
    </>
  );
}

export default webdev;
