import React from "react";
import { Link } from "react-router-dom";
import Herobg from "../../assest/hero.png";
function hero() {
  return (
    <>
      <div className="hero_main">
        <img src={Herobg} alt="Hero" className="herobg" />
        <div className="hero_main1">
          <h1 className="hero_1">
            Elevate Your Business With Our{" "}
            <span className="hero_2">
               <br /> {" "}
            </span>{" "}
            <span className="hero_3">Innovative Digital Solutions</span>
          </h1>
          <p className="hero_4">
          Making your business vision a reality. You are just one click away from getting the best team onboard.
          </p>
          <Link
            target="_blank"
            to="https://calendly.com/prestigeperfections/prestige-web-agency"
          >
            <button className="btn2">Book a Call Now</button>
          </Link>
        </div>
      </div>
    </>
  );
}

export default hero;
