import React from "react";
import Nav from "./landing/nav/nav";
import Footer from "./landing/footer/footer";
import Contact from "./landing/contact/contact";
import {Helmet} from "react-helmet";

function contact() {
  return (
    <>
      <Helmet>
        <title>Contact US | Prestige Perfections</title>
        <meta
          name="description"
          content="Contact us for any queries or information"
        />
      </Helmet>
      <Nav />
      <Contact/>
      <Footer />
    </>
  );
}

export default contact;
