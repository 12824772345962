import React from "react";
import Nav from "./landing/nav/nav";
import Footer from "./landing/footer/footer";
import Testimonials from "./landing/testimonials/testimonials";



function testimonials() {
  return (
    <>
      <Nav />
      <Testimonials />
      <Footer />
    </>
  );
}

export default testimonials;
