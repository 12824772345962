import React from "react";
import Nav from "./landing/nav/nav";
import Footer from "./landing/footer/footer";
import Projects from "./landing/projects/projects";
import { Helmet } from "react-helmet";

function portfolio() {
  return (
    <>
      <Helmet>
        <title>Portfolio | Prestige Perfections</title>
        <meta
          name="description"
          content="We are very happy about our completed projects"
        />
      </Helmet>
      <Nav />
      <Projects />
      <Footer />
    </>
  );
}
export default portfolio;
