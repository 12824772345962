import React from "react";
import Nav from "./landing/nav/nav";
import Footer from "./landing/footer/footer";
import Knowledge from "./landing/knowledge/knowledge";
function blogs() {
  return (
    <>
      <Nav />
      <Knowledge />
      <Footer />
    </>
  );
}

export default blogs;
