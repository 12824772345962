import axios from "axios";

export const deletePortfolio = async (id) => {
  try {
    const response = await axios.delete(
      "http://prestigeperfections.agency/backend/portfolio/delete.php",
      {
        headers: {
          Authorization: "Sclout",
          "Content-Type": "application/json",
        },
        data: {
          PortfolioID: id,
        },
      }
    );
    console.log("response", response.data);
    return response.data;
  } catch (error) {
    return error.message;
  }
};
