import React from "react";
import Logo2 from "../../assest/logo2.png";
import { Link } from "react-router-dom";

import ScrollToTopButton from "../../components/ScrollToTopButton";

function footer() {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      <div className="footer_main">
        <div className="footer_1">
          <div className="footer_2">
            <img src={Logo2} alt="Logo" />
            <p>
              We are a team of experienced and skilled developers who use the
              latest technologies and methodologies to create innovative,
              user-friendly and feature-rich software that helps businesses and
              organizations improve efficiency, drive innovation and make data-
              driven decisions.
            </p>
          </div>
          <div className="footer_3">
            <h1>Company</h1>
            <Link to="/about" onClick={handleClick}>
              <a><p>About Us</p></a>
            </Link>
            <Link to="/team" onClick={handleClick}>
              <a><p>Our Team</p></a>
            </Link>
            <Link to="/portfolio" onClick={handleClick}>
              <a href="javascript:void(0);">
                <p>Portfolio</p></a>
            </Link>

            <Link to="/testimonials" onClick={handleClick}>
              <a href="javascript:void(0);">
                <p>Testimonials</p></a>
            </Link>


            <Link to="/contact" onClick={handleClick}>
              <a href="javascript:void(0);">
                <p>Contact US</p></a>
            </Link>

            <Link to="/faqs" onClick={handleClick}>
              <a href="javascript:void(0);">
                <p>FAQs</p></a>
            </Link>


          </div>


          <div className="footer_3 f_extend">
            <h1>Services</h1>

            <Link to="/webdev" onClick={handleClick}>
            <a href="javascript:void(0);">
              <p>Web & App Development</p>
            </a>
            </Link>

            
            <a href="javascript:void(0);">
              <p>Reputation & Crisis Management</p>
            </a> 
            
            {/* <a href="javascript:void(0);">
              <p>Crisis Management</p>
            </a> */}

            <a href="javascript:void(0);">
              <p>Business Development</p>
            </a> 
            
            

            <h1>Help Center</h1>

            <Link to="/blog" onClick={handleClick}>
              <a href="javascript:void(0);">
                <p>Knowledge Base</p></a>
            </Link>

            <Link to="/privacy-policy" onClick={handleClick}>
              <a href="javascript:void(0);">
                <p>Privacy Policy</p></a>
            </Link>

            <Link to="/terms" onClick={handleClick}>
              <a href="javascript:void(0);">
                <p>Terms and Conditions</p></a>
            </Link>


          </div>


          <div className="footer_3 f_extend">
            <h1>Brands & Partnerships</h1>
              <a href="https://cloutnews.com"><p>Clout News</p></a>
              <a href="https://tiktokliveagency.com"><p>TikTok Live Agency</p></a>
              <a href="https://hostingvender.com"><p>Hosting Vender</p></a>
          </div>


        </div>
        <div className="allrights">
          <div className="allrights_1">
            <p>© 2024 Prestige Perfections. All rights reserved</p>
            <div className="allrights_2">
              <a href="javascript:void(0);">
                <p>
                  {/* <a>Sitemap</a> */}
                </p>
              </a>
            </div>
          </div>
        </div>
        <ScrollToTopButton />
      </div>
    </>
  );
}

export default footer;
