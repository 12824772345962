import axios from "axios";

export const fetchPortfolio = async () => {
  try {
    const response = await axios.get(
      "https://prestigeperfections.agency/backend/portfolio/fetch_portfolio.php",
      {
        headers: {
          Authorization: "Sclout",
          "Content-Type": "application/json",
        },
      }
    );
    console.log("response", response.data);
    if (response.status === 200) {
      return response.data; // Return the data if request is successful
    } else {
      throw new Error("Failed to fetch portfolio data"); // Throw an error if request is not successful
    }
  } catch (error) {
    throw error; // Throw the error for handling in the component
  }
};
