import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom"; 
import Nav from "../landing/nav/nav";
import Footer from "../landing/footer/footer";

function formatDate(dateString) {
  const options = { day: 'numeric', month: 'long', year: 'numeric' };
  const date = new Date(dateString);
  return date.toLocaleDateString('en-GB', options);
}

function NewsDetails() {
  const [article, setArticle] = useState(null);
  const { slug } = useParams(); // Get the slug from URL params

  useEffect(() => {
    console.log("Fetching article with slug:", slug);
    // Fetch article data based on slug
    fetch(`https://prestigeperfections.agency/backend/news/fetch_a_news.php?slug=${slug}`, {
      headers: {
        Authorization: 'Sclout' // Include the API key in the request headers
      }
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Data received:", data);
        if (data.status === 200 && data.data.news_article) {
          console.log("Article received:", data.data.news_article);
          setArticle(data.data.news_article); // Set the article data
        } else {
          // Handle error or missing article
          console.error("Error fetching article:", data.message);
          setArticle({}); // Set empty object to indicate no article
        }
      })
      .catch((error) => {
        console.error("Error fetching article:", error);
        setArticle({}); // Set empty object to indicate no article
      });
  }, [slug]); // Fetch data whenever slug changes

  console.log("Article state:", article);

  if (article === null) {
    return (
      <div>
        <Nav />
        <div className="flex justify-center items-center h-screen">
          Loading Article...
        </div>
        <Footer />
      </div>
    ); // Display loading message while fetching data
  }
  

  const pageTitle = article.title || 'Unknown Title';
  const metaDescription = article.metadesc || 'No description available';
  const authorName = article.author ? article.author.name : 'Unknown';
  const formattedDate = article.date ? formatDate(article.date) : 'Unknown';
  const contentHTML = article.content ? { __html: article.content } : '';

  return (
    <>
      <Helmet>
        <title>{pageTitle} | Prestige Perfections</title>
        <meta name="description" content={metaDescription} />
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content={article.image} /> {/* Assuming article has an image property */}
        <meta property="og:url" content={`https://prestigeperfections.agency/blog/${slug}`} />
        <meta property="og:type" content="article" />
        {/* News Article Schema */}
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "NewsArticle",
            "headline": "${pageTitle}",
            "image": "${article.image}",
            "datePublished": "${article.date}", // Assuming article has a date property
            "description": "${metaDescription}",
            "author": {
              "@type": "Person",
              "name": "${authorName}"
            }
          }
          `}
        </script>
      </Helmet>
      <Nav />
      <div className="team_main">
        <div className="blog_content">
          <h1 className="blog_title">{pageTitle}</h1>
          {article.image && <img className="blog_image" src={article.image} alt="Images" />}
          <div className="k34">
            <div className="knowledge_3">
              <svg
                width={22}
                height={2}
                viewBox="0 0 22 2"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                {/* SVG content */}
              </svg>
              <h2>By: {authorName} | {formattedDate}</h2>
            </div>
          </div>
          <p dangerouslySetInnerHTML={contentHTML}></p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default NewsDetails;
