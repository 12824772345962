import React, { useEffect, useState } from "react";
import K1 from "../../assest/murtes-04-600x344.jpg.png";
import K3 from "../../assest/Icon.png";
import { Link } from "react-router-dom";

function Knowledge() {
  const [articles, setArticles] = useState([]);
  const API_KEY = 'Sclout';

  useEffect(() => {
    fetch("https://prestigeperfections.agency/backend/news/fetch_news.php", {
      headers: {
        'Authorization': API_KEY,
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (response.status === 401) {
          throw new Error('Unauthorized');
        }
        return response.json();
      })
      .then(data => {
        console.log("Data received:", data);
        if (data.status === 200) {
          setArticles(data.data.news_articles || []);
        } else {
          console.error('Failed to fetch news:', data.message);
        }
      })
      .catch(error => console.error('Error fetching news:', error));
  }, []);

  console.log("Rendered articles:", articles);

  const formatDate = (dateString) => {
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  return (
    <div className="knowledge_main">
      <h1 className="world_1">
        PRESTIGE
        <span className="world_2"> KNOWLEDGE BASE</span>
      </h1>
      <p className="world_p">You would love to see what's going around</p>
      <div className="knowledge_1">
        {articles.map(article => (

        <Link to={`/blog/${article.slug}`}>
          <div className="knowledge_2" key={article.id}>
            <h1>{article.title}</h1>
            {/* <p>{article.content}</p> */}
            <img className="kimg" src={article.image || K1} alt="Images" />
            <div className="k34">
              <div className="knowledge_3">
                <svg width={22} height={2} viewBox="0 0 22 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_1_2710)">
                    <path
                      d="M20.5561 1.56885C19.6092 1.44418 17.9557 1.35052 15.5955 1.28785C15.5248 1.28585 15.4648 1.28485 15.4153 1.28485C15.3658 1.28485 15.3128 1.28618 15.2563 1.28885C13.942 1.34085 12.451 1.36785 10.7833 1.36985C8.97431 1.37185 7.37731 1.34518 5.9923 1.28985C5.92164 1.28718 5.86157 1.28585 5.81211 1.28585C5.76264 1.28585 5.70258 1.28652 5.63192 1.28785C4.64263 1.31452 3.78053 1.34652 3.04563 1.38385C1.44863 1.46518 0.48054 1.56018 0.141355 1.66885L0.0141602 1.71285V1.74585H20.9589C21.0578 1.74585 21.1249 1.74518 21.1602 1.74385C21.1956 1.74252 21.2132 1.73952 21.2132 1.73485C21.1708 1.67418 20.9518 1.61885 20.5561 1.56885ZM10.5077 1.30885C11.6101 1.30885 12.6205 1.29652 13.5392 1.27185C14.4578 1.24718 15.1821 1.21335 15.7121 1.17035C16.2421 1.12735 16.5035 1.08018 16.4964 1.02885C16.4894 0.977516 16.2103 0.930183 15.6591 0.88685C15.122 0.845516 14.4048 0.813016 13.5074 0.78935C12.6099 0.765683 11.6454 0.75385 10.6137 0.75385C9.52548 0.75385 8.53266 0.76635 7.63523 0.79135C6.7378 0.81635 6.03117 0.85035 5.51532 0.89335C4.99948 0.93635 4.74156 0.983516 4.74156 1.03485C4.74156 1.08352 4.99948 1.12868 5.51532 1.17035C6.03117 1.21202 6.7272 1.24518 7.60343 1.26985C8.49379 1.29518 9.46188 1.30818 10.5077 1.30885Z"
                      fill="#72839A"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1_2710">
                      <rect
                        width="21.2274"
                        height={1}
                        fill="white"
                        transform="matrix(1 0 0 -1 0 1.74988)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <h2>By {article.author.name}</h2>
              </div>
              <div className="knowledge_4">
                <img src={K3} alt="Icon" />
                <h2>{formatDate(article.date)}</h2>
              </div>
            </div>
            
          </div>
          </Link>


        ))}
      </div>
    </div>
  );
}

export default Knowledge;
