import React from "react";
import { Link } from "react-router-dom";
import Worldimg from "../../assest/world.png";
function world() {
  return (
    <>
      <div className="world_main">
        <h1 className="world_1">
          We have developed <span className="world_2"> more than 130</span>{" "}
          Products Websites worldwide
        </h1>
        <p className="world_p">
          All these products are massive success that boost our portfolio in
          Software Development
        </p>
        <div className="world_3">
          <div className="world_4">
            <h1 className="world_4_1">
              We are the best team in the field of Software Development
            </h1>
            <p className="world_4_2">
              We at Prestige Perfections do all our best to become your technology partner so
              we can grow together in all possible dimensions
            </p>
          </div>
          <div className="world_5">
            <img src={Worldimg} alt="Images" />
          </div>
          <div className="world_6">
            <h1 className="world_6_1">
              Mission, vission and{" "}
              <span className="world_6_2">short history</span> of the company
            </h1>
            <p className="world_6_3">
              As a software development company, we utilize technology-driven
              solutions to organize data, get the best planning and bring the
              perfect decision making
              <br />
              <br />
              Our focus is at mutual growth in collaborative way thus making a
              perfect business model
            </p>
            <Link
              target="_blank"
              to="https://calendly.com/prestigeperfections/prestige-web-agency"
            >
              <button className="btn4">
                Book a Call Now
                <svg
                  width={15}
                  height={8}
                  viewBox="0 0 15 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.6294 2.50635V1.08008C10.6294 0.428958 11.4355 0.0878931 11.9006 0.552981L14.5671 3.25049C14.8772 3.52954 14.8772 3.99463 14.5671 4.27368L11.9006 6.97119C11.4355 7.43628 10.6294 7.09522 10.6294 6.44409V4.98682H1.29663C1.07959 4.98682 0.924561 4.83179 0.924561 4.61475V2.87842C0.924561 2.69239 1.07959 2.50635 1.29663 2.50635H10.6294Z"
                    fill="white"
                  />
                </svg>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default world;
