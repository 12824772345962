import React from 'react'
import choosebg from "../../assest/reason.png"
function choose() {
  return (
    <>
    <div className='choose_main'>
        <img src={choosebg} alt='Images' className='choosebg' />
        <h1 className='choose_1'>
        We have <span className='choose_2'>reasons for you</span> to choose us
        </h1>
        <p className='choose_3'>You just need to share your idea and you will see how we convert that into reality</p>
        <p className='choose_4'>We specialize in analyzing, designing, building, deploying and scaling digital tech products with great service quality and speed and more importantly we do it all with passion.</p>
        <div className='choose_5'>
            <div className='choose_6'>
                <h1 className='choose_7'>Top <br/> Professionals</h1>
                <p className='choose_8'>Our vetted agile product development team build top-notch products.</p>
            </div>
            <div className='choose_6'>
                <h1 className='choose_7'>Cost Effective</h1>
                <p className='choose_8'>Our vetted agile product development team build top-notch products.</p>
            </div>
            <div className='choose_6'>
                <h1 className='choose_7'>24/7 customer support</h1>
                <p className='choose_8'>Our vetted agile product development team build top-notch products.</p>
            </div>
            <div className='choose_6'>
                <h1 className='choose_7'>Complete Ownership</h1>
                <p className='choose_8'>Our vetted agile product development team build top-notch products.</p>
            </div>
        </div>
    </div>
    </>
  )
}

export default choose