import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";

import Questions from './components/Question';

import Nav from './landing/nav/nav';
import Footer from './landing/footer/footer';

const Faq = ({ scrollToForm }) => {
  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    fetch('https://prestigeperfections.agency/backend/faqs/fetch_faqs.php')
      .then(response => response.json())
      .then(data => setFaqs(data))
      .catch(error => console.error('Error fetching FAQs:', error));
  }, []);

  return (
    <>
      <div className="py-10">
        <div className="container mx-auto 2xl:px-0 px-4 xl:max-w-[1200px] w-full">
          <div className="w-[90px] h-[9px] bg-[#EE1B50] mb-3 mx-auto"></div>

          <h1 className="text-neutral-800 lg:text-[46px] text-[28px] font-bold text-center">
            FREQUENTLY ASKED <span className="text-[#EE1B50]">QUESTIONS</span>
          </h1>
          <p className="text-center text-[#737071] lg:text-base text-xs font-normal ">
          Find the answers for the most frequently asked questions about Prestige Perfections Services 

          </p>


          <div className="mt-11 w-full space-y-2 md:border-t md:border-white md:border-opacity-10">
            {faqs.map((data, index) => (
              <div key={index}>
                <Questions
                  question={data.question}
                  answer={data.answer}
                  height={data.height}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

function Faqs() {
  const pageTitle = "FAQ's | Prestige Perfections";
  const metaDescription =
    "Find the answers for the most frequently asked questions about Prestige Perfections Services";

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>

      <Nav/>

      <div className="relative lg:pt-10 pt-10">
        <Faq />
      </div>
    
      <Footer/>
    </>
  );
}

export default Faqs;
