import React from "react";
import Nav from "./nav/nav";
import Hero from "./hero/hero";
import World from "./world/world";
import Choose from "./choose/choose";
import Service from "./service/service";
import Profit from "./profit/profit";
import History from "./history/history";
import Footer from "./footer/footer";
import AdminPage from "../admin/AdminPage";
function index() {
  const isAdmin = localStorage.getItem("token");
  if (isAdmin) {
    return (
      <>
          <AdminPage/>
      </>
    );
  }
  return (
    <>

      <Nav />
      <Hero />
      <World />
      <Choose />
      <Service />
      <History />
      <Profit />
      <Footer />
    </>
  );
}

export default index;
