import React from "react";
import Nav from "./landing/nav/nav";
import Footer from "./landing/footer/footer";
import { Link } from "react-router-dom";
import rsm from "./assest/rsm.jpg";
import { Helmet } from "react-helmet";
function about() {
  return (
    <>
      <Helmet>
        <title>About Us | Prestige Perfections</title>
        <meta name="description" content="We are committed to provide absolute finesse! Welcome to Prestige Perfections Web Agency, the pinnacle of digital ingenuity where every pixel, code, and concept converges to redefine your online presence." />
      </Helmet>
      <Nav />
      <div className="team_main">
        <h1 className="world_1">
          About <span className="world_2"> Us</span>
        </h1>
        <p className="world_p">We are committed to provide absolute finesse!</p>

        <div className="profit_main">
          <div className="profit_2">
            <img src={rsm} alt="Images" className="profitimg" />
          </div>
          <div className="profit_1 ex_profit_1">
            <p>Prestige Perfections, a full-service digital marketing agency founded over a decade ago in Birmingham, UK, is renowned for transforming business visions into reality. Our seasoned team specializes in leveraging social media algorithms and viral marketing techniques to boost online presence and engagement.</p><br />
            <p>From SEO to web design, our tailored solutions cater to the unique needs of clients, particularly in the entertainment industry. With a track record of success and trusted by industry giants like Pitbull and Warner Bros, we pride ourselves on delivering innovative and effective services that make a real impact.</p><br />
            <p>Our Grammy-nominated producers and beat-makers are here to help you craft top-quality music, while our digital solutions enhance your brand's reputation and visibility. At Prestige Perfections, we are more than just a marketing agency; we are your dedicated partners in achieving success.</p><br />
            <p>Whether you're a solo artist looking to make a mark or a business aiming to enhance its digital footprint, we are committed to providing unparalleled service and support. We promise to deliver services that are not only effective but also innovative and tailored to your unique needs.</p><br />
            <p>By staying ahead of industry trends and constantly evolving our offerings, we provide the most relevant and impactful solutions. Join us on this journey and experience the Prestige Perfections difference. Let's make your vision a reality, together.</p>
          </div>
        </div>
        <Link
          target="_blank"
          to="https://calendly.com/prestigeperfections/prestige-web-agency"
        >
          <button className="btnload fullwwidth">Book a Call Now</button>
        </Link>
      </div>

      <Footer />
    </>
  );
}

export default about;
