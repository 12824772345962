import React, { useState } from "react";
import Logo from "../../assest/logo.svg";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

function Nav() {
  const currentPath = useLocation().pathname;

  const [isTranslated, setIsTranslated] = useState(false);

  const handleToggleClick = () => {
    setIsTranslated((prevState) => !prevState);
  };
  return (
    <>
      <div className={`${isTranslated ? "translateFull" : "nav_main"}`}>
        <svg
          onClick={handleToggleClick}
          xmlns="http://www.w3.org/2000/svg"
          className="xmark"
          width={44}
          height={44}
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="#ffffff"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M10 10l4 4m0 -4l-4 4" />
          <path d="M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z" />
        </svg>

        <div className="nav_1">
          <div className="nav_2">
            <div aria-label="Logo" style={{ textAlign: "center" }}>
              <Link to="/">
                <img
                  src={Logo}
                  alt="Logo"
                  className="logo3"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              </Link>
            </div>
            <div className="nav_3">
              <Link
                to="/"
                className={` ${currentPath === "/" ? "active" : "nav_list"}`}>
                Home
              </Link>

              <Link
                to="/portfolio"
                className={` ${
                  currentPath === "/portfolio" ? "active" : "nav_list"
                }`}>
                Portfolio
              </Link>

              <Link
                to="/testimonials"
                className={` ${
                  currentPath === "/testimonials" ? "active" : "nav_list"
                }`}>
                Testimonials
              </Link>

              <Link
                to="/team"
                className={` ${
                  currentPath === "/team" ? "active" : "nav_list"
                }`}>
                Our Team
              </Link>

              {/*
              <Link
                to="/blog"
                className={` ${
                  currentPath === "/blog" ? "active" : "nav_list"
                }`}
              >
                Blogs
              </Link> */}

              <Link
                to="/contact"
                className={` ${
                  currentPath === "/contact" ? "active" : "nav_list"
                }`}>
                Contact Us
              </Link>
            </div>
          </div>
          <Link
            target="_blank"
            to="https://calendly.com/prestigeperfections/prestige-web-agency">
            <button className="btn1">Book a Call</button>
          </Link>
        </div>
      </div>
      <div className="mobilenav">
        <div aria-label="Logo">
          <img src={Logo} alt="Logo" className="logo" />
        </div>
        <svg
          onClick={handleToggleClick}
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-menu-2"
          width={44}
          height={44}
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="#ffffff"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M4 6l16 0" />
          <path d="M4 12l16 0" />
          <path d="M4 18l16 0" />
        </svg>
      </div>
    </>
  );
}

export default Nav;
