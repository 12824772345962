import React from "react";
import Nav from "./landing/nav/nav";
import Footer from "./landing/footer/footer";
import Team from "./landing/team/team";

function team() {
  return (
    <>
      <Nav />
      <Team/>
      <Footer />
    </>
  );
}

export default team;
