import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { fetchPortfolio } from "../api/portfolio/fetch_portfolio";
import axios from "axios";
import { deletePortfolio } from "../api/portfolio/deletePortfolio";


function AdminPage() {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.clear();
    navigate("/portfolio");
  };
  const [portfolios, setPortfolios] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    image: "",
    site: "",
    objective: "",
    strategy: "",
    result: "",
  });
  const [formDataUpdate, setFormDataUpdate] = useState({
    name: "",
    id: "",
    image: "",
    site: "",
    objective: "",
    strategy: "",
    result: "",
  });
  const handleUpdate = () => {
    const headers = {
      Authorization: "Sclout",
      "Content-Type": "application/json",
    };
    axios
      .post(
        "http://prestigeperfections.agency/backend/portfolio/update.php",
        formData,
        { headers }
      )
      .then((response) => {
        console.log("Data sent successfully:", response.data);
        setFormData({
          name: "",
          image: "",
          site: "",
          objective: "",
          strategy: "",
          result: "",
        });
        fetchPortfolioData();
      })
      .catch((error) => {
        console.error("Error sending data:", error);
      });
  };
  const handleDelete = (id) => {
    // Display confirmation dialog
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this portfolio?"
    );

    if (confirmDelete) {
      deletePortfolio(id).then((data) => {
        alert("Portfolio Deleted successfully");
        fetchPortfolio().then((data) => {
          setPortfolios(data.data.portfolios);
        });
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleChangeUpdate = (e) => {
    const { name, value } = e.target;
    setFormDataUpdate((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCreate = () => {
    const headers = {
      Authorization: "Sclout",
      "Content-Type": "application/json",
    };
    axios
      .post(
        "http://prestigeperfections.agency/backend/portfolio/create.php",
        formData,
        { headers }
      )
      .then((response) => {
        console.log("Data sent successfully:", response.data);
        alert("Data sent successfully");
        setFormData({
          name: "",
          image: "",
          site: "",
          objective: "",
          strategy: "",
          result: "",
        });
        fetchPortfolioData();
      })
      .catch((error) => {
        console.error("Error sending data:", error);
      });
  };
  const handleUpdatePortfolio = () => {
    const headers = {
      Authorization: "Sclout",
      "Content-Type": "application/json",
    };
    axios
      .post(
        "http://prestigeperfections.agency/backend/portfolio/update.php",
        formDataUpdate,
        { headers }
      )
      .then((response) => {
        console.log("Data Updated successfully:", response.data);
        alert("Data Updated successfully");
        setFormDataUpdate({
          name: "",
          id: "",
          image: "",
          site: "",
          objective: "",
          strategy: "",
          result: "",
        });
        fetchPortfolioData();
      })
      .catch((error) => {
        console.error("Error sending data:", error);
      });
  };

  useEffect(() => {
    fetchPortfolioData();
  }, []);

  const fetchPortfolioData = () => {
    fetchPortfolio().then((data) => {
      setPortfolios(data.data.portfolios);
    });
  };


  return (
    <div style={containerStyle}>
      <div style={logoutContainerStyle}>
        <button style={logoutButtonStyle} onClick={handleLogout}>
          Logout
        </button>
      </div>
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <h2>Portfolio List</h2>
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}>
          {portfolios.map((portfolio) => (
            <div
              key={portfolio.PortfolioID}
              style={cardStyle}>
              <img
                src={portfolio.image}
                alt={portfolio.name}
                style={imageStyle}
              />
              <h3>{portfolio.name}</h3>
              {/* <p>Portfolio ID: {portfolio.PortfolioID}</p>
              <p>Objective: {portfolio.objective}</p>
            <p>Result: {portfolio.result}</p> */}
            {/* <p>Strategy: {portfolio.strategy}</p> */}
              <p>
                Site: <a href={portfolio.site}>{portfolio.site}</a>
              </p>
              <button
                onClick={() => handleDelete(portfolio.PortfolioID)}
                style={deleteButtonStyle}>
                Delete
              </button>
            </div>
          ))}
        </div>
      </div>
      <div
        style={{
          overflow: "auto",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginLeft: "10%",
          marginRight: "10%",
          alignItems: "center",
        }}>
        <div style={contentStyle}>
          <h2>Create Portfolio</h2>
          <form onSubmit={handleCreate} style={formStyle}>
            <div style={inputGroupStyle}>
              <label>Name:</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </div>
            <div style={inputGroupStyle}>
              <label>Image URL:</label>
              <input
                type="text"
                name="image"
                value={formData.image}
                onChange={handleChange}
              />
            </div>
            <div style={inputGroupStyle}>
              <label>Site URL:</label>
              <input
                type="text"
                name="site"
                value={formData.site}
                onChange={handleChange}
              />
            </div>
            <div style={inputGroupStyle}>
              <label>Objective:</label>
              <textarea
                name="objective"
                value={formData.objective}
                onChange={handleChange}
              />
            </div>
            <div style={inputGroupStyle}>
              <label>Strategy:</label>
              <textarea
                name="strategy"
                value={formData.strategy}
                onChange={handleChange}
              />
            </div>
            <div style={inputGroupStyle}>
              <label>Result:</label>
              <textarea
                name="result"
                value={formData.result}
                onChange={handleChange}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                margin: 20,
              }}>
              <button
                type="submit"
                style={{ ...submitButtonStyle, backgroundColor: "#28a745" }}>
                Create
              </button>
            </div>
          </form>
        </div>
        <div style={contentStyle}>
          <h2>Update Portfolio</h2>
          <form onSubmit={handleUpdatePortfolio} style={formStyle}>
            <div style={inputGroupStyle}>
              <label>Name:</label>
              <input
                type="text"
                name="name"
                value={formDataUpdate.name}
                onChange={handleChange}
              />
            </div>
            <div style={inputGroupStyle}>
              <label>Portfolio ID:</label>
              <input
                type="text"
                name="id"
                value={formDataUpdate.id}
                onChange={handleChangeUpdate}
              />
            </div>
            <div style={inputGroupStyle}>
              <label>Image URL:</label>
              <input
                type="text"
                name="image"
                value={formDataUpdate.image}
                onChange={handleChange}
              />
            </div>
            <div style={inputGroupStyle}>
              <label>Site URL:</label>
              <input
                type="text"
                name="site"
                value={formDataUpdate.site}
                onChange={handleChange}
              />
            </div>
            <div style={inputGroupStyle}>
              <label>Objective:</label>
              <textarea
                name="objective"
                value={formDataUpdate.objective}
                onChange={handleChange}
              />
            </div>
            <div style={inputGroupStyle}>
              <label>Strategy:</label>
              <textarea
                name="strategy"
                value={formDataUpdate.strategy}
                onChange={handleChange}
              />
            </div>
            <div style={inputGroupStyle}>
              <label>Result:</label>
              <textarea
                name="result"
                value={formDataUpdate.result}
                onChange={handleChange}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                margin: 20,
              }}>
              <button
                type="submit"
                onClick={handleUpdate}
                style={{
                  ...submitButtonStyle,
                  backgroundColor: "#007bff",
                  marginLeft: 20,
                }}>
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
const cardStyle = {
  width: "300px",
  margin: "20px",
  padding: "20px",
  border: "1px solid #ccc",
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
};

const imageStyle = {
  width: "250px",
  marginBottom: "10px",
  height: '100px',

};

const deleteButtonStyle = {
  backgroundColor: "#ff5555",
  color: "#fff",
  padding: "8px 16px",
  border: "none",
  borderRadius: "4px",
  cursor: "pointer",
};
const containerStyle = {
  position: "relative",
  minHeight: "100vh",
  backgroundColor: "#f5f5f5", // Light gray background
};

const logoutContainerStyle = {
  position: "absolute",
  top: 20,
  right: 20,
};

const logoutButtonStyle = {
  padding: "8px 16px",
  fontSize: 16,
  fontWeight: "bold",
  color: "#fff",
  backgroundColor: "#007bff", // Blue color for button
  border: "none",
  borderRadius: 4,
  cursor: "pointer",
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Box shadow for button
};

const contentStyle = {
  paddingTop: 60, // Adjust the top padding to make space for the logout button
  textAlign: "center",
  fontSize: 24,
  color: "#333",
};

const formStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const inputGroupStyle = {
  marginBottom: 16,
  width: "100%",
  maxWidth: 400,
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
};

const submitButtonStyle = {
  padding: "8px 16px",
  fontSize: 16,
  fontWeight: "bold",
  color: "#fff",
  backgroundColor: "#007bff", // Blue color for button
  border: "none",
  borderRadius: 4,
  cursor: "pointer",
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Box shadow for button
};

export default AdminPage;
